import { validation } from 'site-modules/shared/components/form-validation/validation';

export const CARAMEL_FINANCING = {
  text: 'No-pressure financing',
  icon: '/icons/percent-in-hand.svg',
  width: 30,
  height: 30,
};
export const CARAMEL_DELIVERY = {
  text: 'Pickup or delivery',
  icon: '/icons/delivery-truck.svg',
  width: 38,
  height: 30,
};
export const CARAMEL_CHECKOUT = {
  text: 'Simple Online Checkout',
  icon: '/icons/finger-selecting.svg',
  width: 38,
  height: 30,
};

export const CARAMEL_ENTRY_POINT_DATA = {
  benefits: [CARAMEL_CHECKOUT, CARAMEL_FINANCING, CARAMEL_DELIVERY],
  cta: 'Start Purchase',
  header: 'Online Checkout and Delivery',
};

export const DEAL_IN_PROGRESS = 'in_progress';
export const DEAL_NOT_STARTED = 'not_started';
export const DEAL_COMPLETED = 'completed';
export const DEAL_INACTIVE = 'inactive';

export const DEAL_STATUS_PENDING = 'Pending Sale';
export const DEAL_STATUS_SOLD = 'Recently Sold';

// TODO: should be removed when clean up TED-6659-Caramel-Edm-Signup

export const CARAMEL_CHECKOUT_STEP = 'caramelCheckout';
export const CARAMEL_SURVEY_STEP = 'caramelSurvey';
export const CARAMEL_THANK_YOU_STEP = 'caramelThankYou';

export const MAX_CHARACTERS_COUNT = 170;

export const CHECKOUT_SIGN_UP_CREATIVE_ID = 'checkout-sign-up';
export const CHECKOUT_SURVEY_CREATIVE_ID = 'checkout-survey';
export const CHECKOUT_SIMULATION_CREATIVE_ID = 'used-summary_checkout-simulation';

export const CARAMEL_CREATIVE_ID = {
  [CARAMEL_CHECKOUT_STEP]: CHECKOUT_SIGN_UP_CREATIVE_ID,
  [CARAMEL_SURVEY_STEP]: CHECKOUT_SURVEY_CREATIVE_ID,
};

export const CTA_NEXT = 'Next';

export const CARAMEL_CHECKOUT_SOCIAL_BUTTONS = [
  { name: 'Google', imageSrc: '/img/car-buying/caramel/google-icon.svg' },
  { name: 'Facebook', icon: 'facebook2', iconClass: 'text-blue-50' },
  { name: 'Apple', icon: 'apple-carplay' },
];

export const FORM_FIELDS = {
  firstName: 'first_name',
  lastName: 'last_name',
  phoneNumber: 'phone',
  zipCode: 'zip',
  email: 'email',
};

export const VALIDATORS = {
  [FORM_FIELDS.firstName]: {
    test: validation.validateName,
    errorText: 'Please correct your name. Special characters cannot be included.',
  },
  [FORM_FIELDS.lastName]: {
    test: validation.validateName,
    errorText: 'Please correct your name. Special characters cannot be included.',
  },
  [FORM_FIELDS.phoneNumber]: {
    test: validation.validatePhoneNumber,
    errorText: 'Please enter a valid phone number.',
  },
  [FORM_FIELDS.zipCode]: {
    test: validation.validateZip,
    errorText: 'Please enter a valid ZIP code.',
  },
  [FORM_FIELDS.email]: {
    test: validation.validateEmail,
    errorText: 'Please enter a valid email.',
  },
};
